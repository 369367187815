.fm-task-map {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.fm-task-map-holder {
  flex: 1 1;
  position: relative;
}

.fm-task-map-filters {
  width: 100%;
  height: 47px;
  display: flex;
  flex-direction: row;
  border-top: 1px solid #E8E7EA;
  padding: 8px 13px 6px 13px;
  background: #fff;
  z-index: 9999;
  flex: 0 0 47px;
  overflow-x: scroll;
  white-space: nowrap;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.fm-task-map-filters::-webkit-scrollbar {
  display: none;
}

.fm-task-map-filters-type {
  display: inline-block;
  color: #fff;
  margin: 5px;
  border-radius: 2px;
  font-size: 13px;
  line-height: 16px;
  font-weight: 600;
  padding: 3px 6px;
  cursor: pointer;
}

.fm-task-map-filters-type i {
  font-size: 12px;
  margin: 0 6px 0 2px;
  line-height: 16px;
}

.fm-task-map-cards {
  width: 100%;
  height: 47px;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #e8e7ea;
  padding: 8px 13px 6px;
  background: #fff;
  z-index: 9999;
  flex: 0 0 47px;
  overflow-x: scroll;
  white-space: nowrap;
}

.fm-task-map-card {
  display: inline-block;
  color: #fff;
  margin: 5px;
  border-radius: 2px;
  font-size: 13px;
  line-height: 16px;
  font-weight: 600;
  padding: 3px 7px;
  cursor: pointer;
  background-color: $light-background-color;
}

@media (prefers-color-scheme: dark) {
  .fm-task-map-filters {
    background-color: $background-color-dark;
    border-top-color: $border-color-dark;
  }
  .fm-task-map-cards {
    background-color: $background-color-dark;
    border-bottom-color: $border-color-dark;
  }
  .fm-task-map-card {
    background-color: $light-background-color-dark;
  }
}
